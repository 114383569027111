import { createApp } from "vue";
import App from "./App.vue";
import "./styles/main.css";
import SwiperClass /* swiper modules... */ from "swiper";
import "swiper/css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import { registerGlobComp } from "@/components/registerGlobComp";

SwiperClass.use([
  /* swiper modules... */
]);

const app = createApp(App);

registerGlobComp(app);

app.use(VueAwesomeSwiper).mount("#app");
