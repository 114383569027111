<template>
  <div class="h-full overflow-hidden bg-gray-100">
    <a-spin :spinning="isLoading" wrapperClassName="mainContainer" size="large">
      <div class="fixed top-4 left-0 right-0 z-10 text-center text-gray-600">
        <span
          ><span class="font-semibold">{{ activeIndex + 1 }}</span> /
          {{ pages }}</span
        >
      </div>

      <swiper
        style="height: 100%"
        :slides-per-view="1"
        :space-between="50"
        @swiper="onSwiper"
        :modules="[Zoom]"
        :zoom="true"
        @zoomChange="onZoomChangeThrottle"
        @activeIndexChange="onActiveIndexChange"
        @touchMove="onTouchMove"
      >
        <swiper-slide v-for="page in pages" :key="page">
          <div class="swiper-zoom-container h-full">
            <VuePDF :pdf="pdf" :page="page" :width="width" @loaded="onLoaded" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="fixed right-8 bottom-8 z-10">
        <!-- <button
        class="rounded-lg px-3 py-1 border-2 border-blue-200"
        @click="onClickShowInfo"
      >
        view
      </button> -->
        <a-button @click="onClickShowInfo" type="primary"> view </a-button>
      </div>
    </a-spin>

    <a-drawer
      :closable="false"
      placement="bottom"
      :open="isShowInfo"
      @close="isShowInfo = false"
      height="650"
      :bodyStyle="{
        padding: 0,
      }"
    >
      <a-tabs
        centered
        :tabBarStyle="{
          position: 'sticky',
          top: 0,
          background: '#FFF',
          zIndex: 10,
        }"
      >
        <a-tab-pane key="1" tab="Read Duration">
          <div class="py-2 h-full overflow-y-auto">
            <DurationChart :durations="records" ref="durationChart" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Operations">
          <!-- <ul class="px-4 py-2">
            <li v-for="(record, index) in operationRecord" :key="index">
              {{ record }}
            </li>
          </ul> -->
          <div class="px-4">
            <a-collapse>
              <a-collapse-panel
                v-for="record in records"
                :key="record.pageNum"
                :header="`Page ${record.pageNum} (view times: ${record.times})`"
              >
                <ul class="px-4 py-2">
                  <li v-for="(record, index) in record.operations" :key="index">
                    {{ record }}
                  </li>
                </ul>
              </a-collapse-panel>
            </a-collapse>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-drawer>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";

import { Swiper, SwiperSlide } from "swiper/vue";
import { VuePDF, usePDF } from "@tato30/vue-pdf";
import "@tato30/vue-pdf/style.css";

import { useWindowSize } from "@vueuse/core";
import { Zoom } from "swiper/modules";
import "swiper/css/zoom";
import DurationChart from "./DurationChart.vue";

import "swiper/css";

import { useThrottleFn } from "@vueuse/core";

const { width } = useWindowSize();

const { pdf, pages } = usePDF({
  url: "/test.pdf",
  enableXfa: true,
});

const isLoading = ref(true);

const onSwiper = (swiper) => {
  console.log(swiper);
};
// const onSlideChange = (swiper) => {
//   const index = swiper.activeIndex;
//   operationRecord.value.push("Slide to Page " + (index + 1));
// };

// const scale = ref(1);
let recordScale = 1;

const onZoomChange = (swiper, scale) => {
  console.log(scale);
  recordScale = scale;
  makeOperationRecord(
    activeIndex,
    `Page ${activeIndex + 1}: Zoom change ${scale} times`
  );
};

const onZoomChangeThrottle = useThrottleFn(onZoomChange, 100);

let startRecord = 0;
const records = ref([]);

const onLoaded = () => {
  isLoading.value = false;
};
startRecord = Date.now();

watch(pages, () => {
  if (pages != null && pages.value > 0) {
    for (let i = 0; i < pages.value; i++) {
      records.value[i] = {
        pageNum: i + 1,
        duration: 0,
        times: i == 0 ? 1 : 0,
        operations: [],
      };
    }
  }
});

let activeIndex = 0;
const onActiveIndexChange = (swiper) => {
  makeRecord();
  activeIndex = swiper.activeIndex;
  makeOperationRecord(activeIndex, "Slide to Page " + (activeIndex + 1));
  records.value[activeIndex].times++;
};

const makeRecord = () => {
  records.value[activeIndex].duration += Date.now() - startRecord;
  startRecord = Date.now();
};

const makeOperationRecord = (activeIndex, operation) => {
  records.value[activeIndex].operations.push(operation);
};

const isShowInfo = ref(false);
const onClickShowInfo = () => {
  isShowInfo.value = true;
  makeRecord();
};
// const onClickOutside = () => {
//   isShowInfo.value = false;
// };

const durationChart = ref(null);
watch(isShowInfo, () => {
  if (isShowInfo.value && durationChart.value) {
    durationChart.value.setOptions();
  }
});

const onTouchMove = () => {
  if (recordScale > 1) {
    makeOperationRecord(
      activeIndex,
      "Drag move to read page " + (activeIndex + 1)
    );
  }
};
</script>
<style scoped>
.mainContainer,
:deep(.mainContainer .ant-spin-container) {
  height: 100%;
}

.pdf-content {
  overflow: hidden;
  height: 100%;
  position: relative;
  .pdf-viewer-wrapper {
    overflow: auto;
    position: relative;
    height: 100%;
  }
}
</style>
