<template>
  <a-card class="mx-auto w-[700px]">
    <h1 class="font-bold">Test</h1>
    <p>test for recording the words when user selected or copied.</p>
    <p>test for recording the duration of mouse stays on the page.</p>
    <p>test for recording the mouse move heatmap on ever page and display.</p>
  </a-card>
  <div class="relative flex justify-center p-6 space-x-4">
    <div>
      <!-- <div class="mb-6 text-center">
        <button
          class="px-3 py-1 mx-2 font-bold border-2 rounded-md border-sky-100"
          @click="page = page > 1 ? page - 1 : page"
        >
          Prev
        </button>
        <span
          ><span class="font-semibold">{{ page }}</span> / {{ pages }}</span
        >
        <button
          class="px-3 py-1 mx-2 font-bold border-2 rounded-md border-sky-100"
          @click="page = page < pages ? page + 1 : page"
        >
          Next
        </button>
      </div> -->
      <div class="pdfContainer w-[800px]" id="pdfContainer">
        <div v-for="page in pages" :key="page" class="relative">
          <!-- <VuePDF
            class="mb-2"
            :pdf="pdf"
            :page="page"
            text-layer
            @text-loaded="onTextLoaded"
            @dblclick="ondblclick"
            @mouseenter="onMouseEnter(page)"
            @mouseleave="onMouseLeave(page)"
            @loaded="onLoaded(page)"
            fit-parent
          >
          </VuePDF> -->
          <div
            class="mb-6"
            @mouseenter="onMouseEnter(page)"
            @mouseleave="onMouseLeave(page)"
          >
            <DesktopPdfPage
              :pdf="pdf"
              :page="page"
              @text-loaded="onTextLoaded"
              @dblclick="ondblclick"
              @loaded="onLoaded(page)"
              :isShowHeatmap="isShowHeatmap"
            >
            </DesktopPdfPage>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="sticky right-0 top-6">
        <a-button
          :type="isShowHeatmap ? 'default' : 'primary'"
          class="mb-2"
          @click="isShowHeatmap = !isShowHeatmap"
          >{{ isShowHeatmap ? "hide" : "show" }} heatmap test display</a-button
        >
        <a-card
          hoverable
          class="mb-4 overflow-y-auto"
          title="Mouse duration"
          :bodyStyle="{ hight: '200px', width: '400px' }"
        >
          <ul style="text-align: left">
            <li v-for="item in mouseDurationRecord" :key="item.pageNum">
              <span class="font-semibold">Page {{ item.pageNum }}: </span>
              <span>{{ displayTime(item.mouseDuration) }}</span>
            </li>
          </ul>
        </a-card>
        <a-card
          hoverable
          :bodyStyle="{ hight: '600px', width: '400px' }"
          class="mb-4 overflow-y-auto"
          title="Selection and copied text"
        >
          <ul style="text-align: left">
            <li v-for="(text, index) in recordText" :key="index">
              <span class="font-semibold">{{ text.type }}: </span>
              <span>{{ text.text }}</span>
            </li>
          </ul>
        </a-card>
      </div>
    </div>
  </div>
  <div></div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { usePDF } from "@tato30/vue-pdf";
import "@tato30/vue-pdf/style.css";
import { useEventListener } from "@vueuse/core";
import { displayTime } from "@/utils";

import DesktopPdfPage from "./DesktopPdfPage.vue";

const isShowHeatmap = ref(false);

// const page = ref(1);

const { pdf, pages } = usePDF({
  url: "/test.pdf",
  // url: "https://mozilla.github.io/pdf.js/web/compressed.tracemonkey-pldi-09.pdf",
  enableXfa: true,
});

const recordText = ref([]);
const mouseDurationRecord = ref([]);

const onTextLoaded = () => {};

let startRecord = 0;

const onMouseEnter = () => {
  startRecord = Date.now();
};

const onMouseLeave = (page) => {
  mouseDurationRecord.value[page - 1].mouseDuration += Date.now() - startRecord;
  startRecord = 0;
};

watch(pages, () => {
  if (pages != null && pages.value > 0) {
    for (let i = 0; i < pages.value; i++) {
      mouseDurationRecord.value[i] = {
        pageNum: i + 1,
        mouseDuration: 0,
      };
    }
  }
});

onMounted(() => {
  const target = document.querySelector("#pdfContainer");

  useEventListener(target, "selectstart", () => {
    useEventListener(document, "selectionchange", logSelection);
  });

  useEventListener(target, "mouseup", () => {
    const selection = document.getSelection().toString().trim();
    if (selection) {
      recordText.value.push({
        type: "selection text",
        text: document.getSelection().toString(),
      });
    }
  });

  useEventListener(document, "copy", () => {
    navigator.clipboard.readText().then((text) => {
      recordText.value.push({
        type: "copy text",
        text,
      });
    });
  });
});

const logSelection = () => {
  console.log(document.getSelection().toString());
};

// const onClick = () => {
//   console.log("click");
//   console.log(window.getSelection());
// };

const ondblclick = () => {
  console.log(window.getSelection());
};
</script>
