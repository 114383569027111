// import Vue3CountryIntl from 'vue3-country-intl';
// import 'vue3-country-intl/lib/vue3-country-intl.css';
import {
  Button,
  Drawer,
  Spin,
  Tabs,
  Collapse,
  Alert,
  Card,
} from "ant-design-vue";

export function registerGlobComp(app) {
  // app.component(Vue3CountryIntl.name, Vue3CountryIntl);
  app
    .use(Button)
    .use(Drawer)
    .use(Spin)
    .use(Tabs)
    .use(Collapse)
    .use(Alert)
    .use(Card);
}
