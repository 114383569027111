<template>
  <v-chart class="chart" :option="option" autoresize />
</template>

<script setup>
import { defineProps, defineExpose, onMounted } from "vue";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { GridComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, provide } from "vue";
import { displayTime } from "@/utils";

const props = defineProps(["durations"]);

use([CanvasRenderer, BarChart, GridComponent]);

provide(THEME_KEY, "light");

// const categoryData = props.durations.map((e) => "Page " + e.pageNum);
// const durationValue = props.durations.map((e) => e.duration);

const option = ref({
  grid: {
    top: 0,
    left: 60,
    bottom: 0,
    right: 10,
  },
  yAxis: {
    type: "category",
    data: props.durations.map((e) => "Page " + e.pageNum),
    inverse: true,
  },
  xAxis: {
    type: "value",
    axisLabel: {
      show: false,
    },
  },
  series: [
    {
      data: props.durations.map((e) => e.duration),
      type: "bar",
      barMaxWidth: 20,
      label: {
        show: true,
        precision: 1,
        position: "right",
        width: 200,
        fontSize: 12,
        // color: "#FFF",
        formatter: ({ value }) => {
          return value > 0 ? displayTime(value) : "";
        },
      },
    },
  ],
});

const setOptions = () => {
  option.value.yAxis.data = props.durations.map((e) => "Page " + e.pageNum);
  option.value.series[0].data = props.durations.map((e) => e.duration);
  console.log(option.value);
};

onMounted(() => {
  setOptions();
});

defineExpose({ setOptions });
</script>

<style scoped>
.chart {
  height: 100vh;
}
</style>
