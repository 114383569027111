<template>
  <div>
    <a-spin class="relative" :spinning="!isLoaded">
      <VuePDF
        :pdf="pdf"
        :page="page"
        fit-parent
        text-layer
        @loaded="onLoaded"
        ref="pdfPageRef"
      >
      </VuePDF>
      <div
        v-if="isLoaded"
        :style="{ zIndex: isShowHeatmap ? 10 : -10 }"
        class="absolute top-0 bottom-0 left-0 right-0"
      >
        <div class="w-full h-full" ref="containerRef"></div>
      </div>
    </a-spin>
    <div>
      <a-button @click="getData"> getData </a-button>
    </div>
  </div>
</template>
<script setup>
import { VuePDF } from "@tato30/vue-pdf";
import { defineProps, onMounted, nextTick, ref, watch, computed } from "vue";
import { useThrottleFn, useEventListener } from "@vueuse/core";

const props = defineProps(["page", "pdf", "isShowHeatmap"]);

const isLoaded = ref(false);
const onLoaded = () => {
  isLoaded.value = true;
};

// const wrapperRef = ref(null);
const containerRef = ref(null);

const pdfId = computed(() => {
  return "pdfId_" + props.page;
});

let heatmap = null;

watch(isLoaded, () => {
  if (isLoaded.value) {
    nextTick(() => {
      // eslint-disable-next-line no-undef
      heatmap = h337.create({
        container: containerRef.value,
        radius: 30,
        blur: 1,
        max: 3,
        min: 0,
        // backgroundColor with alpha so you can see through it
        backgroundColor: "rgba(255, 255, 255, 0.75)",
        // gradient: {
        //   ".1": "#313695",
        //   ".2": "#4575b4",
        //   ".3": "#74add1",
        //   ".4": "#abd9e9",
        //   ".5": "#e0f3f8",
        //   ".6": "#ffffbf",
        //   ".7": "#fee090",
        //   ".8": "#fdae61",
        //   ".9": "#f46d43",
        //   "1.0": "#d73027",
        // },
      });
      // var heatmapContainer = wrapperRef.value;

      // heatmapContainer.onmousemove = heatmapContainer.ontouchmove = function (e) {
      //   // we need preventDefault for the touchmove
      //   // e.preventDefault();
      //   var x = e.layerX;
      //   var y = e.layerY;
      //   if (e.touches) {
      //     x = e.touches[0].pageX;
      //     y = e.touches[0].pageY;
      //   }

      //   heatmap.addData({ x: x, y: y, value: 1 });
      // };

      // heatmapContainer.onclick = function (e) {
      //   var x = e.layerX;
      //   var y = e.layerY;
      //   heatmap.addData({ x: x, y: y, value: 1 });
      //   console.log(heatmap.getData());
      // };
    });
  }
});

const onMousemove = (e) => {
  if (heatmap && isLoaded.value) {
    // console.log(e);
    var x =
      e.target.role == "presentation"
        ? e.target.offsetLeft + e.layerX
        : e.layerX;
    var y =
      e.target.role == "presentation"
        ? e.target.offsetTop + e.layerY
        : e.layerY;

    // if (e.target.role == "presentation") {
    //   console.log(e);
    // }

    // if (e.target.nodeName == "SPAN") {
    //   // console.log(e);
    //   console.log(x, y);
    //   heatmap.addData({ x: x, y: y, value: 0.5 });
    // } else {
    //   heatmap.addData({ x: x, y: y, value: 0.5 });
    //   // console.log(x, y);
    // }
    // console.log(x, y, e);
    heatmap.addData({ x: x, y: y, value: 1 });
  }
};

// eslint-disable-next-line no-unused-vars
const onClick = (e) => {
  if (e.target.nodeName != "SPAN") {
    console.log("inner", e);
    console.log(e, e.target.offsetLeft, e.target.offsetTop);
  } else {
    console.log("oo");
  }

  // if (heatmap) {
  //   var x = e.layerX;
  //   var y = e.layerY;
  //   if (e.touches) {
  //     x = e.touches[0].pageX;
  //     y = e.touches[0].pageY;
  //   }
  //   heatmap.addData({ x: x, y: y, value: 1 });
  // }
};

// eslint-disable-next-line no-unused-vars
const onMousemoveThrottle = useThrottleFn(onMousemove, 50);

const pdfPageRef = ref(null);

onMounted(() => {
  // pdfPageRef.value.child.id = pdfId.value;
  // console.log(pdfPageRef.value.$el.querySelector(".textLayer"));
  const textLayerElement = pdfPageRef.value.$el.querySelector(".textLayer");
  if (textLayerElement) {
    textLayerElement.id = pdfId.value;

    nextTick(() => {
      const target = document.querySelector(`#${pdfId.value}`);
      // console.log(target);
      useEventListener(target, "mousemove", onMousemoveThrottle);
      // useEventListener(target, "click", (e) => {
      //   onClick(e);
      // });
    });
  }
});

const getData = () => {
  console.log(heatmap.getData());
};
</script>
