<template>
  <div class="h-full">
    <DesktopView v-if="isLargeScreen" />
    <!-- <TestView v-if="isLargeScreen"></TestView> -->
    <MobileView v-else />
  </div>
</template>

<script setup>
// eslint-disable-next-line no-unused-vars
import DesktopView from "./components/DesktopView.vue";
import MobileView from "./components/MobileView.vue";
// import TestView from "./components/TestView.vue";

import { useMediaQuery } from "@vueuse/core";

const isLargeScreen = useMediaQuery("(min-width: 1024px)");
</script>

<style></style>
